import Notice from 'components/ui/notice'

import { formatAmount } from 'utils/formatters'

import { IExchangeData } from 'store/fetchers/loans/enum'
import { ICurrency } from 'store/fetchers/currencies'

import { ReactElement } from 'react'
import cn from 'classnames'

import percentStyles from '../ltv-option/styles.module.scss'

import styles from './styles.module.scss'
import { floorNumber } from 'utils/floor-number'
import { useTranslation } from 'utils/use-translation'

export default function TermsBlock({
  exchange,
  to,
  from,
}: {
  exchange: IExchangeData
  to: ICurrency
  from: ICurrency
}): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsItem}>
        <div className={styles.termsName}>
          <p>{t('loan_term')}</p>
          <Notice alt={t('borrow_crypto_assets')}>
            {t('loan_depends_on_options')}
          </Notice>
        </div>
        <div className={styles.termsValue}>{t('unlimited')}</div>
      </div>
      <div className={styles.termsItem}>
        <div className={styles.termsName}>
          <p>{t('monthly_interest_rate')}</p>
          <Notice alt={`how much is the interest rate`}>
            {t('apr_will_be')}&nbsp;
            {exchange?.interest_percent ? <span>{exchange?.interest_percent}%</span> : null}
          </Notice>
        </div>
        <div className={styles.termsValue}>
          <div className={cn(percentStyles.ltvOption, percentStyles.ltvOptionSelected)}>
            <span
              className={cn(
                styles.termsValue__text_inactive,
                styles.termsValue__text_space_right,
              )}
            >
              2.25%
            </span>
            <span className={cn(styles.termsValue__text_highlighted)}>
              {exchange?.interest_percent && floorNumber(Number(exchange?.interest_percent) / 12, 3)}%
            </span>
          </div>
        </div>
      </div>
      <div className={styles.termsItem}>
        <div className={styles.termsName}>
          <p>{t('monthly_interest_capital')}</p>
          <Notice alt={'how to borrow cryptocurrency'}>
            {t('rate_accrued_every_month')}
          </Notice>
        </div>
        <div className={styles.termsValue}>
          <div>{formatAmount(exchange?.interest_amounts?.month, to, to?.is_stable ? 2 : to?.decimal_places, true)}</div>
        </div>
      </div>
    </div>
  )
}
